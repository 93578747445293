import * as React from "react"

function LoadyHorse() {
  return (
    <div className="loady-horse-wrapper">
      <svg
        id="Layer_1"
        version="1.1"
        viewBox="0 0 128 128"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient
            id="e"
            x1="0"
            y1="0"
            x2="0"
            y2="80"
            gradientUnits="userSpaceOnUse"
            gradientTransform="rotate(10)"
          >
            <stop stop-color="orange" offset="0" />
            <stop stop-color="red" offset="1" />
          </linearGradient>
        </defs>
        <g id="XMLID_1815_">
          <polyline
            class="loady-horse"
            stroke="url(#e)"
            id="XMLID_1828_"
            points="54.6,67 54.6,58.4 51.5,54 45.3,54 48.4,58.4 54.6,58.4  "
          />
          <polyline
            class="loady-horse"
            stroke="url(#e)"
            id="XMLID_1827_"
            points="73.3,67 73.3,58.4 76.4,54 82.7,54 79.5,58.4 73.3,58.4  "
          />
          <g id="XMLID_1824_">
            <polyline
              class="loady-horse"
              stroke="url(#e)"
              id="XMLID_1826_"
              points="56.2,26.2 53,21 46,7.4 41.1,18.5 46.8,36 38.9,51.1 41.1,57.5 46.6,85.4     51.8,98.8   "
            />
            <polyline
              class="loady-horse"
              stroke="url(#e)"
              id="XMLID_1825_"
              points="71.8,26.2 75,21 82,7.4 86.9,18.5 81.2,36 89.1,51.1 86.9,57.5 81.4,85.4     76.2,98.8   "
            />
          </g>
          <polygon
            class="loady-horse"
            stroke="url(#e)"
            id="XMLID_1823_"
            points="65.9,19.7 70.5,22.6 74.8,37.2 71.7,35.6 64,45.6 56.3,35.6 53.2,37.2 57.5,22.6    62.1,19.7 64.1,17  "
          />
          <polyline
            class="loady-horse"
            stroke="url(#e)"
            id="XMLID_1822_"
            points="71,116.2 69.6,119.2 64,120.6 58.4,119.2 57,116.2  "
          />
          <g id="XMLID_1820_">
            <polyline
              class="loady-horse"
              stroke="url(#e)"
              id="XMLID_1821_"
              points="73.3,58.4 73.3,92.8 77.2,102.8 72.6,115.6 64,117.1 55.4,115.6 50.8,102.8     54.6,92.8 54.6,58.4   "
            />
          </g>
          <polyline
            class="loady-horse"
            stroke="url(#e)"
            id="XMLID_1819_"
            points="58.1,98.8 56.2,103.1 58.1,108  "
          />
          <polyline
            class="loady-horse"
            stroke="url(#e)"
            id="XMLID_1818_"
            points="70,98.8 71.9,103.1 70,108  "
          />
          <polyline
            class="loady-horse"
            stroke="url(#e)"
            id="XMLID_1817_"
            points="46.1,7.4 48,24.4 46.8,36  "
          />
          <polyline
            class="loady-horse"
            stroke="url(#e)"
            id="XMLID_1816_"
            points="81.8,7.4 79.9,24.4 81.1,36  "
          />
        </g>
      </svg>
    </div>
  )
}

export default LoadyHorse
