import * as React from "react"
import { Link } from "gatsby"
import { useStables } from "../hooks/open-the-stables"
import { horseThemAround } from "../helpers/shuffle-horses"

const horsePhrases = ["Horse me", "I'm feeling horsey", "HORSE", "*Whinnies*"]

const handlePhrase = () => {
  const phraseKey = Math.floor(Math.random() * horsePhrases.length)
  return phraseKey
}

const HorseButton = props => {
  // set horseText = noHorseText to remove button text
  // set a className on the component as usual, it will pass it along
  const allHorses = useStables()
  let horses, horse
  const isBrowser = typeof window !== "undefined"
  horse = allHorses[0]
  if (isBrowser) {
    if (sessionStorage.getItem("horses") !== null) {
      horses = JSON.parse(sessionStorage.getItem("horses"))
    } else {
      horses = horseThemAround(allHorses)
    }
  } else {
    horses = horseThemAround(allHorses)
  }

  horse = horses.pop()
  horses.unshift(horse)

  if (isBrowser) {
    sessionStorage.setItem("horses", JSON.stringify(horses))
  }

  return (
    <>
      <Link
        to={`${horse.node.path}`}
        activeClassName="active"
        className={props.className ? props.className : "horseButton"}
      >
        {props.horseText !== "noHorseText"
          ? horsePhrases[handlePhrase()]
          : null}
        {props.horse}
      </Link>
    </>
  )
}

export default HorseButton
