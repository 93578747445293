import * as React from "react"
import HorseButton from "../../../components/horse-button"

import Layout from "../../../components/layout"
import LoadyHorse from "../../../components/loady-horse"
import Seo from "../../../components/seo"

function HorseTimes() {
  return (
    <Layout bodyClass="archiveOrg horse-magazine">
      <Seo title="Horse or no Horse" />
      <div className="pageWrapper">
        <h1>Horse Magazine</h1>
        <p>
          That's right, it's an issue of Horse Times, the leading equestrian
          magazine in the Middle East!
        </p>
        <div className="iframeWrapper">
          <LoadyHorse />
          <iframe
            src="https://archive.org/embed/horsetimes"
            width=""
            height="384"
            frameborder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowfullscreen
          ></iframe>
        </div>
        <HorseButton />
      </div>
    </Layout>
  )
}

export default HorseTimes
