import { useStaticQuery, graphql } from "gatsby"

export const useStables = () => {
  const { allSitePage } = useStaticQuery(
    graphql`
      query Stables {
        allSitePage(filter: { id: { regex: "/SitePage /horses/" } }) {
          edges {
            node {
              id
              path
            }
          }
        }
      }
    `
  )
  return allSitePage.edges
}
